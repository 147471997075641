import React from 'react';
import "./css/wordsearch.css"

import $ from 'jquery'
import { WordSearchController } from './WordSearchController';
export const jQuery = $

console.alert = (str)=> window.alert(str);
export class NoorWordSearch extends React.Component{
	constructor(props){
		super(props);

	}
	componentDidMount(){
		const {words, grid, handleMatch} = this.props;
		this.$el = $(this.el);
		
		const gridX =grid?.[0]?.length;
		const gridY = grid?.length;
		this.gridX = gridX;
		this.gridY = gridY;
		if( words && grid){
			new WordSearchController("#wordboard", words?.map(word=>word.word?.toLocaleUpperCase()), grid, handleMatch);
		}
		
		
	}
	componentWillUnmount(){

	}

	render(){
		const height = this.gridY * 30;
		const width = this.gridX * 30;

		return (
		<svg viewBox={`0 0 ${height} ${width}`} xmlns="http://www.w3.org/2000/svg">
		<foreignObject x="0" y="0" width={width} height={height}>

<div xmlns="http://www.w3.org/1999/xhtml"></div>
		
		<div className="game" ref={el => this.el = el} style={{display: this.props.hidden? 'none' :'block'}}>
		<div id = "wordboard" style={{touchAction: 'none'}}></div>

		</div>
</foreignObject>
</svg>)
		
	}


}

/** the different directions/orientations a word can flow in the word grid!
 *
 * note: vertical - left -> right
 *		 horizontal - top > bottom
 *		 primary diagonal - upper left corner -> lower right corner
 * 		 secondary diagonal - upper right corner -> lower left corner
 *
 * 'backwards' at the end of the string refers to it going the opposite direction (so
 *  bottom -> top or lower left -> upper right)
 */
 export var paths = { 

	vert: "vertical",
	horizon: "horizontal",
	priDiag: "primaryDiagonal",
	secDiag: "secondaryDiagonal",

	vertBack: "verticalBackwards",
	horizonBack: "horizonBackwards",
	priDiagBack: "primaryDiagonalBackwards",
	secDiagBack: "secondaryDiagonalBackwards",

};

/** this object sets up the matrix bounds for each orientation (just to ensure when inserting
 * a word into the board in a given path, the word doesnt exceed the size of the matrix)
 *
 * @param {Number} x row of current matrix index
 * @param {Number} y column of current matrix index
 * @param {Number} s size (width or height, either one as they should be equal) of the matrix of letters
 */
export var bounds = { 

	[paths.vert]: (x, y, s) => (x < s),
	[paths.horizon]: (x, y, s) => (y < s),
	[paths.priDiag]: (x, y, s) => (x < s) && (y < s),
	[paths.secDiag]: (x, y, s) =>  (x < s) && (y >= 0),

	[paths.vertBack]: (x, y, s) => (x >= 0),
	[paths.horizonBack]: (x, y, s) => (y >= 0),
	[paths.priDiagBack]: (x, y, s) => (x >= 0) && (y >= 0),
	[paths.secDiagBack]: (x, y, s) => (x >= 0) && (y < s)

};

/** this object takes the given matrix row/colum and increments it in the 
 * direction of the path given
 *
 * @param {Number} x matrix row to increment
 * @param {Number} y matrix column to increment
 * @return incremented x and y coordinates (by a factor of 1)
 */
export var incr = { 

	[paths.vert]: (x, y) => ({x: x+1, y: y}),
	[paths.horizon]: (x, y) => ({x: x, y: y+1}),
	[paths.priDiag]: (x, y) => ({x: x+1, y: y+1}),
	[paths.secDiag]: (x, y) => ({x: x+1, y: y-1}),

	[paths.vertBack]: (x, y) => ({x: x-1, y: y}),
	[paths.horizonBack]: (x, y) => ({x: x, y: y-1}),
	[paths.priDiagBack]: (x, y) => ({x: x-1, y: y-1}),
	[paths.secDiagBack]: (x, y) => ({x: x-1, y: y+1})

};


