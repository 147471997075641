
import { WordSearchView } from "./WordSearchView";






const defaultWords =['Cat', 'Dog', 'Mouse', 'House', 'Chicken', "Rabbit", "Kangaroo", "Tiger"]
const defaultGrid = [
	[
		"U",
		"A",
		"F",
		"F",
		"W",
		"O",
		"U",
		"G",
		"E",
		"B",
		"E",
		"A",
		"D",
		"F",
		"Z",
		"Q",
		"M",
		"Z",
		"H",
		"U"
	],
	[
		"V",
		"I",
		"N",
		"U",
		"Q",
		"Z",
		"O",
		"A",
		"B",
		"E",
		"T",
		"O",
		"N",
		"O",
		"J",
		"H",
		"C",
		"L",
		"G",
		"L"
	],
	[
		"K",
		"R",
		"M",
		"Q",
		"E",
		"D",
		"A",
		"X",
		"C",
		"a",
		"t",
		"n",
		"L",
		"T",
		"B",
		"O",
		"Y",
		"O",
		"C",
		"F"
	],
	[
		"I",
		"E",
		"H",
		"G",
		"E",
		"J",
		"Z",
		"I",
		"U",
		"M",
		"e",
		"I",
		"L",
		"E",
		"R",
		"B",
		"M",
		"P",
		"M",
		"K"
	],
	[
		"C",
		"O",
		"J",
		"S",
		"Q",
		"R",
		"U",
		"J",
		"o",
		"k",
		"U",
		"C",
		"M",
		"L",
		"Z",
		"V",
		"H",
		"X",
		"Q",
		"D"
	],
	[
		"M",
		"I",
		"S",
		"E",
		"L",
		"V",
		"I",
		"u",
		"c",
		"C",
		"Z",
		"T",
		"Y",
		"N",
		"Y",
		"D",
		"R",
		"C",
		"G",
		"X"
	],
	[
		"G",
		"M",
		"I",
		"U",
		"G",
		"Z",
		"s",
		"i",
		"M",
		"Z",
		"X",
		"V",
		"i",
		"W",
		"B",
		"P",
		"V",
		"K",
		"Y",
		"K"
	],
	[
		"L",
		"W",
		"E",
		"M",
		"R",
		"e",
		"h",
		"X",
		"V",
		"e",
		"N",
		"I",
		"N",
		"g",
		"N",
		"S",
		"J",
		"Q",
		"K",
		"Z"
	],
	[
		"B",
		"U",
		"A",
		"B",
		"T",
		"C",
		"N",
		"G",
		"L",
		"s",
		"B",
		"B",
		"H",
		"H",
		"e",
		"L",
		"F",
		"S",
		"D",
		"X"
	],
	[
		"N",
		"X",
		"J",
		"J",
		"A",
		"Q",
		"U",
		"L",
		"S",
		"u",
		"P",
		"I",
		"W",
		"T",
		"G",
		"r",
		"O",
		"G",
		"C",
		"S"
	],
	[
		"T",
		"J",
		"Y",
		"O",
		"G",
		"C",
		"Z",
		"Y",
		"H",
		"o",
		"Z",
		"N",
		"C",
		"A",
		"D",
		"A",
		"P",
		"J",
		"S",
		"J"
	],
	[
		"S",
		"D",
		"F",
		"O",
		"K",
		"M",
		"O",
		"C",
		"Y",
		"H",
		"V",
		"W",
		"J",
		"G",
		"C",
		"t",
		"K",
		"G",
		"H",
		"A"
	],
	[
		"P",
		"K",
		"F",
		"N",
		"V",
		"Y",
		"M",
		"X",
		"S",
		"Q",
		"E",
		"L",
		"E",
		"D",
		"I",
		"i",
		"O",
		"I",
		"Z",
		"A"
	],
	[
		"W",
		"U",
		"W",
		"Z",
		"N",
		"T",
		"Q",
		"X",
		"F",
		"Y",
		"V",
		"J",
		"H",
		"O",
		"I",
		"b",
		"U",
		"N",
		"P",
		"S"
	],
	[
		"K",
		"H",
		"C",
		"K",
		"N",
		"H",
		"Y",
		"D",
		"o",
		"g",
		"Z",
		"V",
		"O",
		"Z",
		"G",
		"b",
		"S",
		"A",
		"I",
		"O"
	],
	[
		"H",
		"D",
		"Q",
		"K",
		"C",
		"N",
		"S",
		"H",
		"K",
		"C",
		"C",
		"M",
		"V",
		"Z",
		"Q",
		"a",
		"V",
		"P",
		"S",
		"H"
	],
	[
		"S",
		"M",
		"Y",
		"U",
		"M",
		"L",
		"M",
		"V",
		"X",
		"A",
		"G",
		"D",
		"B",
		"M",
		"J",
		"R",
		"H",
		"A",
		"K",
		"K"
	],
	[
		"U",
		"F",
		"G",
		"Z",
		"N",
		"G",
		"Z",
		"I",
		"T",
		"G",
		"O",
		"X",
		"L",
		"I",
		"X",
		"O",
		"O",
		"A",
		"N",
		"P"
	],
	[
		"A",
		"H",
		"L",
		"V",
		"A",
		"G",
		"F",
		"A",
		"Q",
		"F",
		"K",
		"a",
		"n",
		"g",
		"a",
		"r",
		"o",
		"o",
		"M",
		"V"
	],
	[
		"A",
		"U",
		"G",
		"H",
		"M",
		"A",
		"V",
		"Z",
		"O",
		"L",
		"Q",
		"G",
		"J",
		"U",
		"R",
		"U",
		"V",
		"P",
		"Q",
		"C"
	]
];

export function WordSearchController(
	gameId, words=defaultWords, grid=defaultGrid, handleMatch = (data)=>console.log(data)) {
	
	const view = new WordSearchView(grid, words, gameId, handleMatch);
	view.setUpView();
	view.triggerMouseDrag();
}
