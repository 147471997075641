import React, { useEffect, useRef, useState } from "react";
import {
  ActionIcon,
  Button,
  Container,
  Grid,
  Group,
  TimelineItem,
  Title,
} from "@mantine/core";
import { useWordSearch } from "../hooks/useWordSearch";
import styled from "styled-components";
import { Link } from "gatsby";
import { WordSearchViewer } from "../components/WordSearchViewer";
import { Wrapper } from "../components/site/Wrapper";
import { IconPlayerPause, IconPlayerPlay } from "@tabler/icons";
import { NoorWordSearch } from "../components/site/NoorWordSearch";
import {wordFilterFromString} from '../utils/word';

const Main = styled.main`
overscroll-behavior: none;

`

const WordListContainer = styled.div`
  display: grid;
  grid-column-gap: 10px;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
`;

const Word = styled.div``;
const CompletedWord = styled(Word)`
  color: #000000aa;
  text-decoration: line-through;
`;

const WordListItem = ({ word }) => {
  if (word.completed) {
    return <CompletedWord>{word.word}</CompletedWord>;
  }
  return <Word>{word.word}</Word>;
};

const StyledMain = styled.article`
  margin: auto;
`;
const TimerWrapper = styled.div`
  min-height: 60px;
`;
const TimerContainer = styled.div`
  display: grid;
  grid-column-gap: 10px;
  grid-auto-flow: column;
`;

const PausedContainer = styled.div`
  height: 512px;
  width: 512px;
`;

const Timer = ({ paused, setPaused, timer }) => {
  return (
    <TimerContainer>
      <nav></nav>
      {!paused ? timer.elapsed : ""}
    </TimerContainer>
  );
};

const Puzzle = ({ pageContext, ...props }) => {

  const svgRectElem = useRef();
  const [paused, setPaused] = useState(false);
  const displayWords = pageContext?.meta?.puzzleData?.words || [];
  const puzzleWords = displayWords.map(wordFilterFromString())
  const hiddenPuzzleWords = pageContext?.meta?.puzzleData?.hiddenWords || [];
  const {
    words,
    updateWord,
    checkPoints,
    completedCount,
    hiddenWordsCompletedCount,
    hiddenWordsTotalCount,
    visibleWords,
    hiddenCompletedWords,
    visibleWordsTotalCount,

    wordSearch,
    defaultViewerProps,
    timer,
  } = useWordSearch(puzzleWords, undefined, hiddenPuzzleWords);

  useEffect(() => {
    timer.startTimer();
    console.log("props", pageContext, props);
  }, []);

  useEffect(() => {
    console.log(words);
    console.log(svgRectElem.current);
  }, [words]);

  const {
    puzzleData: { title = "Puzzle Title" },
    description = "Puzzle Description lorem ipsum",
  } = pageContext.meta;

  const handleMatch = (data = {}) => {
    const { word } = data;
    updateWord(word, { completed: !word.completed });
  };

  if (!wordSearch) {
    return (
      <Wrapper
        current={title}
        category={[<Link to="..">{pageContext.node.name}</Link>]}
      >
        <main>Generating</main>
      </Wrapper>
    );
  }

  const TimerLayout = () => {
    return (
      <>
        {timer.timerActive ? (
          <ActionIcon
            aria-description="Pause"
            disabled={!timer.timerActive}
            onClick={() => {
              setPaused(true);
              timer.pauseTimer();
            }}
          >
            <IconPlayerPause size={18} />
          </ActionIcon>
        ) : (
          <ActionIcon
            aria-description="Start"
            disabled={timer.timerActive}
            onClick={() => {
              setPaused(false);
              timer.startTimer();
            }}
          >
            <IconPlayerPlay size={18} />
          </ActionIcon>
        )}
      </>
    );
  };

  //Passing Hidden to prevent unmount
  return (
    <Wrapper
      current={title}
      category={[<Link to="..">{pageContext.node.name}</Link>]}
    >
      <Main>
        <div className="container sm-p1 md-px-4">
          <div className="row gx-0 text-center">
            <div className="col-sm-12 col-md-12 col-lg-8 g-0">
              <div className="sm-p1 md-p-3">
                <TimerWrapper className="d-flex justify-content-between align-items-center">
                  <Timer paused={paused} timer={timer} setPaused={setPaused} />
                  <div>
                    <div>
                      Words: {completedCount} / {visibleWordsTotalCount}
                    </div>
                    {hiddenWordsTotalCount ? (
                      <div>
                        Hidden: {hiddenWordsCompletedCount} /{" "}
                        {hiddenWordsTotalCount}
                      </div>
                    ) : null}
                  </div>
                  <TimerLayout />
                </TimerWrapper>
                <NoorWordSearch
                  hidden={paused}
                  handleMatch={handleMatch}
                  words={wordSearch?.data?.words}
                  grid={wordSearch?.data?.grid}
                />
              </div>
            </div>

            <div className="col-sm-12 col-md-12 col-lg-4">
              <div className="md-p-3 text-start">
                <div className="container ms-lg-3 md-px-4 g-0 md-g-3">
                  <div className="row gx-0 text-start">
                    <div className="py-sm-3 p-md-0 col-sm-12 gx-2 gy-2 text-start fs-2">
                      {title}
                    </div>

                    {visibleWords.sort((itema, itemb)=>(itema.completed ? 1: -1)).map((word) => {
                      return (
                        <div className="col-sm-12 col-md-6 gx-2 gy-2 text-start text-uppercase">
                          <WordListItem word={word} />
                        </div>
                      );
                    })}

                    {hiddenCompletedWords.map((word) => {
                      return (
                        <div className="col-sm-6 col-md-6 gx-2 gy-2 text-start text-uppercase">
                          <WordListItem word={word} />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Main>
    </Wrapper>
  );
};

export default Puzzle;
