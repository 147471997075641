import $ from 'jquery';
import { incr, paths, bounds } from './index';


function listToMatrix(list, elementsPerSubArray) {
    var matrix = [], i, k;

    for (i = 0, k = -1; i < list.length; i++) {
        if (i % elementsPerSubArray === 0) {
            k++;
            matrix[k] = [];
        }

        matrix[k].push(list[i]);
    }

    return matrix;
}

/** This object contains the necessary functions to create the 'view' of the word search,
 * which essentially refers to displaying the puzzle and handling mouse events!
 *
 * @author Noor Aftab
 *
 * @param {Array[]} matrix - 2D array containing the filled word search grid
 * @param {Array[]} list - 2D array containing the list of words in the grid
 * @param {String} gameId - div ID for the word search container
 
 */


export function WordSearchView(matrix, words, gameId, handleMatch, svg) {
	"use strict";
	const list = listToMatrix(words, 4);



	//object to hold oft-used class/id/attribute names!
	var names = {
		cell: "cell",
		pivot: "pivot",
		selectable: "selectable",
		selected: "selected",
		path: "path"
	};

	//object to hold oft-used class/id selectors 
	var select = {
		cells: "." + names.cell,
		pivot: "#" + names.pivot,
		selectable: "." + names.selectable,
		selected: "." + names.selected
	};

	var searchGrid = {
		row: "row",
		column: "column"
	};

	/* creates the word search puzzle grid and the table containing the list
	 * of words to find
	 */
	this.setUpView = function () {

		createSearchGrid(matrix, names.cell, searchGrid.row, searchGrid.column, gameId);
		// createListOfWords(list, listId);

	};

	/** used strings because it was easy enough for a small program like this, wanted
	to explore jQuery's capabilities! **/
	/** used buttons because <td> would expand when adding border when found - stylistic purposes**/
	/** this funcion makes a 'table' of divs to store each letter in the matrix of letters
	 * created in wordsearchlogic.js
	 *
	 * @param {Array[]} matrix
	 * @param {String} cellName
	 * @param {String} rowAttr
	 * @param {String} colAttr
	 * @param {String} boardId
	 */
	function createSearchGrid(matrix, cellName, rowAttr, colAttr, boardId) {
		let outerWrapper= "<div/>"
		let rowWrapper = "<div/>";
		let rowElementType = "<div />"
		let letterElementType = "<button/>";
		
		
		outerWrapper= `<svg 
		

			/>`
		rowWrapper = "<g/>";
		rowElementType = "<g />"
		letterElementType = "<text/>";
		const OuterWrapper = $(outerWrapper);
		//loops through rows
		for (var i = 0; i < matrix.length; i++) {

			//creates a div for the table row and gives it a row class
			var row = $(rowElementType);
			row.attr({ class: "boardRow" }); //only really used once, so it's not in a variable


			//loops through columns
			for (var j = 0; j < matrix[i].length; j++) {

				//each letter in the row is a button element
				var letter = $(letterElementType); //i hearbuttons are preferred for clickable actions


				//the letter is given a cell class, and given row and column attributes!
				letter.attr({
					class: cellName,
					y: i*30,
					x: j*30,
					[rowAttr]: i,
					[colAttr]: j
				}).text(matrix[i][j]); //sets text of button to the respective matrix index


				//adds letter to the larger row element
				letter.appendTo(row);

			}
			const WrapperElement = $(rowWrapper);
			row.appendTo(WrapperElement);

			//adds the row of letters to the larger game board element
			WrapperElement.appendTo($(OuterWrapper));
		}
		OuterWrapper.appendTo(boardId)
		

	}



	/** this function encapsulates all the mouse events for making a move by breaking it down
	 * into three main parts: pressing the mouse down (mousedown), dragging it (mouseenter),
	 * and finally releasing the mouse (mouseup)!
	 */
	this.triggerMouseDrag = function (evt) {
		const touches = evt?.changedTouches?.length;
		if(touches >1){
			return;
		}
		
		console.log('start',evt)
		//empty array to store the selected cells in a move
		var selectedLetters = [];

		// //empty string to store the word made by a 
		var wordMade = '';

		//variable to store if the mouse is down
		var mouseIsDown = false;

		/** executes when the mouse is pressed down on a letter in the
		 * search grid
		 */
		const mousedown = function () {
			//sets true that mouse is down
			mouseIsDown = true;

			//selects the pressed cell
			$(this).addClass(names.selected);

			//sets the pressed cell to be the 'pivot' of the move
			$(this).attr({ id: names.pivot });

			//highlights all the possible paths the user may go to select more letters
			highlightValidDirections($(this), matrix, names.selectable);

		}
		$(select.cells).on('touchstart', mousedown);
		$(select.cells).mousedown(mousedown);

		/** this code executes when the mouse is down and the user starts moving their
		 * mouse inside the puzzle container!
		 */
		const mouseEnter = function (event) {
			// console.alert('Mouse down', evt)
			
			let realTarget = this;

			if (event?.originalEvent?.changedTouches?.[0]){
				var myLocation = event.originalEvent.changedTouches[0];
				realTarget = document.elementFromPoint(myLocation.clientX, myLocation.clientY);
			}

			console.log('move', realTarget)
			//ensures the mouse is down and the cell the mouse is on is on a valid path
			if (mouseIsDown && $(realTarget).hasClass(names.selectable)) {

				//holds the direction of the path the mouse is currently on
				var currentDirection = $(realTarget).attr(names.path);
				console.log(currentDirection)
				//unselects selected cells
				for (var i = 0; i < selectedLetters.length; i++) {

					selectedLetters[i].removeClass(names.selected);

				}

				//empties the array of selected letters
				selectedLetters = [];

				//empties string of the word being constructed 
				wordMade = '';

				//resets the range of cells to select
				var cells = selectCellRange(select.cells, $(realTarget), names.path, currentDirection, selectedLetters, wordMade);

				wordMade = cells.word;
				selectedLetters = cells.array;

			}

		}
		$(select.cells).on('touchmove', mouseEnter);
		$(select.cells).mouseenter(mouseEnter);

		/** this code calls the endMove function when the mouse is released - it mostly checks
		 * the word made and whether it's a word to be found, as well as resetting variables
		 * to allow another move
		 */
		const mouseUp = function (evt) {
			console.log('end', evt)
			endMove();

		}
		
		$(select.cells).on('touchend',mouseUp);
		$(select.cells).mouseup(mouseUp);

		/** if the user is playing the game and moves their mouse out of the word grid, this function
		 * makes it so that the move automatically ends - this makes pressing the mouse down and
		 * accidentally/purposely leaving the board less annoying to deal with!
		 */
		const mouseLeave = function () {

			if (mouseIsDown) { //checks that the user is indeed pressing their mouse down (therefore, playing)

				endMove();

			}

		}
		$(gameId).on('touchleave', mouseLeave)
		$(gameId).mouseleave(mouseLeave);

		/** this function handles everything ending a move should consist of - resetting variables
		 * for a new move and checking if a proper word to find has been made
		 */
		function endMove() {

			//sets mouse down as false since the mouse is now up
			mouseIsDown = false;

			//checks if a word on the list was selected
			if (validWordMade(wordMade, words)) {

				$(select.selected).addClass("found");

			}

			//unselects any selected letters
			$(select.selected).removeClass(names.selected);

			//removes the direction attributes of any cells (prevents strange behavior)
			$(select.cells).removeAttr(names.path);

			//removes the pivot's ID so a new pivot can be selected 
			$(select.pivot).removeAttr("id");

			//remove selectability of selectable cells 
			$(select.selectable).removeClass(names.selectable);

			//empties the word string and selected cells' array
			wordMade = '';
			selectedLetters = [];

		}

	};

	/* highlights all the valid directions in the matrix from where mouse is first clicked, like
	 * top -> bottom, left -> right, and both diagonals!
	 *
	 * @param {jQuery} selectedCell - DOM element the mouse pressed down on (a cell in the word search puzzle!)
	 * @param {Array[]} matrix - the puzzle 2D array
	 * @param {String} makeSelectable - selector to make an element selectable
	 */
	function highlightValidDirections(selectedCell, matrix, makeSelectable) {

		//gets the row and column of where the cell the mouse pressed on is
		var cellRow = parseInt(selectedCell.attr(searchGrid.row));
		var cellCol = parseInt(selectedCell.attr(searchGrid.column));

		//converts the global paths object into an array
		Object.keys(paths).forEach(function (path) {
			//makes each cell in each of the paths selectable
			makeRangeSelectable(cellRow, cellCol, matrix.length, paths[path], makeSelectable);

		});

	}

	/** this functions makes a given path selectable but giving each cell in the path a 'selectable' class!
	 * this makes it so that the player can only select cells on specific paths (which makes selecting vertically,
	 * horizontally, and diagonally much less of a hassle!)
	 *
	 * @param {Number} x - starting x-coordinate/row of the path
	 * @param {Number} y - starting y-coordinate/column of the path
	 * @param {Number} l - length/size of the matrix
	 * @param {String} p - name of the path (e.g. vertical, primaryDiagonalBackwards)
	 * @param {String} selectable - selector to make a DOM element selectable
	 */
	function makeRangeSelectable(x, y, l, p, selectable) {

		/** initialized variables: x - starting row, incremented to exclude the pivot
		 *						   y - starting column, incremented to exclude the pivot
		 *
		 * condition: x & y to stay within recommended bounds for path p
		 *			  (determined by object bounds)
		 *
		 * increments: x & y - incremented by function determined for path p (by
		 *			   object 'incr')
		 */
		for (var i = incr[p](x, y).x, j = incr[p](x, y).y; //initialized variables
			bounds[p](i, j, l); //condition
			i = incr[p](i, j).x, j = incr[p](i, j).y) { //increments
			//select the specific DOM elements with the specific row/column attribute values
			$("[" + searchGrid.row + "= " + i + "][" + searchGrid.column + "= " + j + "]")
				.addClass(selectable) //makes it selectable
				.attr({ [names.path]: p }); //gives it a path attribute with the value of p

		}

	}

	/** this function finds and selects the range of cells from the pivot (first selected cell) to
	 * the cell the mouse is currenty hovering on, altogether going from end to end on the puzzle
	 * matrix
	 *
	 * @param {String} cellsSelector - selector name for cells in the search grid
	 * @param {Array} selectedCells
	 * @param {jQuery} hoveredCell - cell the mouse is hovering on
	 * @param {String} pathAttr - path/direction attribute
	 * @param {String} path - value of the path attribute
	 * @param {String} wordConstructed - word user makes by dragging around on the puzzle
	 * @return returns an object containing: the word constructed and the array of selected DOM cells!
	 */
	function selectCellRange(cellsSelector, hoveredCell, pathAttr, path, selectedCells, wordConstructed) {
		//variable to hold index of cell hovered on
		var hoverIndex;

		//variable to hold index of pivot
		var pivotIndex;

		//selector for cells in the particular path the mouse is on
		var cellRange = cellsSelector + "[" + pathAttr + " =" + path + "]";

		//setting indices depending on how the paths flow
		switch (path) {

			case paths.vert:
			case paths.horizon:
			case paths.priDiag:
			case paths.secDiag:

				//hoverIndex > pivotIndex 
				hoverIndex = hoveredCell.index(cellRange) + 1;
				pivotIndex = 0;

				//sets up wordConstructed with the pivot's letter (to start it off)
				wordConstructed = $(select.pivot).text();

				//using the pivot text, selects cells and adds their text to wordConstructed
				wordConstructed = selectLetters(selectedCells, wordConstructed, cellRange, pivotIndex, hoverIndex);


				break;

			case paths.vertBack:
			case paths.horizonBack:
			case paths.priDiagBack:
			case paths.secDiagBack:

				//hoverIndex < pivotIndex
				hoverIndex = hoveredCell.index(cellRange);
				pivotIndex = $(cellRange).length;

				//selects range of cells between the pivot and the cell the mouse is on
				wordConstructed += selectLetters(selectedCells, wordConstructed, cellRange, hoverIndex, pivotIndex);

				//adds pivot text to the end
				wordConstructed += $(select.pivot).text();

				break;

		}

		return { word: wordConstructed, array: selectedCells };

	}

	/** this function selects the range of cells between the pivot cell and the
	 * the cell the mouse is hovered, and adds their text to the constructed word's string
	 *
	 * @param {Array} selectedCells - array to hold
	 * @param {String} wordConstructed - word being created by user
	 * @param {String} range - the path on which to select cells
	 * @param {Number} lowerIndex - index of the lower cell
	 * @param {Number} upperIndex - index of the higher cell
	 * @return returns the word made during the selection process!
	 */
	function selectLetters(selectedCells, wordConstructed, range, lowerIndex, upperIndex) {

		//only goes through the the range between the pivot and wherever the mouse is on the path!
		$(range).slice(lowerIndex, upperIndex).each(function (item) {
			console.log(upperIndex, item);
			//selects the cell
			$(this).addClass(names.selected);
			if(item == upperIndex) {
				$(this).addClass('highlight');
			}
			//adds it to the array of cells
			selectedCells.push($(this));

			//updates the word being made to include the newest cell's letter
			wordConstructed += $(this).text();

		});

		return wordConstructed;

	}

	/** checks if the word a user made after a move is an actual word to find, and
	 * if so, sets the word as found! otherwise, nothing happens (so the move is
	 * essentially ignored)
	 *
	 * @param {Array[]} wordList - matrix of words in the grid
	 * @param {String} wordToCheck - word to check for validity
	 * @return true if the word made is a word in the list
	 */
	function validWordMade(wordToCheck, words) {
		if(words.includes(wordToCheck) || words.includes(reversedWord(wordToCheck))){
			handleMatch({word: wordToCheck})
			// $(`.listWord[text = "${wordToCheck}"]`).addClass("found");
			return true;

		}
		return false;
	}

	/** checks if all the words in the puzzle have been found, what method was used to
	 * solve the puzzle, and updates the h2 instructions heading accordingly
	 *
	 * @param {String} fullList - selector for words in the wordlist div
	 * @param {String} foundWordsList - selector found words in the wordlist div
	 * @return true if the entire word search has been solved
	 */
	function checkPuzzleSolved(fullList, foundWordsList) {

		//if all the words in the list to find have been found (no. of words to find == no. of found words)
		if ($(fullList).length == $(foundWordsList).length) {
			return true;

		}

		return false;

	}

	/** reverses a string! (e.g. 'muscat' becomes 'tacsum')
	 *
	 * @param {String} word - word to reverse
	 * @return the reversed word
	 */
	function reversedWord(word) {

		//creates empty string to store reversed word
		var reversedWord = "";

		//loops through from end of word to the beginning (instead of traditional beginning to end)
		for (var i = word.length - 1; i >= 0; i--) {

			//adds the character to reversed word
			reversedWord += word.charAt(i);

		}

		return reversedWord;

	}

}
